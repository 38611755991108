export const sendOrderEmail = async ({ toEmail, toName, subject, body, attachments }) => {
    console.log("Starting sendOrderEmail function with params:", { toEmail, toName, subject, body, attachments });

    let response;

    try {
        if (attachments && attachments.length > 0) {
            // Jeśli są załączniki, używamy FormData
            const formData = new FormData();
            formData.append("toEmail", toEmail);
            formData.append("toName", toName);
            formData.append("subject", subject);
            formData.append("body", body);

            attachments.forEach((file) => {
                formData.append("attachments", file);
            });

            console.log("Using FormData to send email with attachments.");
            response = await fetch(`${process.env.REACT_APP_API_URL}/Email/send-with-attachments`, {
                method: "POST",
                body: formData,
            });

        } else {
            // Jeśli nie ma załączników, wysyłamy JSON
            console.log("Using JSON to send email.");
            response = await fetch(`${process.env.REACT_APP_API_URL}/Email/send`, {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ toEmail, toName, subject, body }),
            });
        }

        console.log("Fetch response received:", response);

        if (!response.ok) {
            const errorDetails = await response.json();
            console.error("Error sending email:", errorDetails.message);
            return { success: false, error: errorDetails.message };
        }

        const result = await response.json();
        console.log("Email sent successfully:", result.message);
        return { success: true };
    } catch (error) {
        console.error("Error sending email:", error);
        return { success: false, error: error.message };
    }
};
