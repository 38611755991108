export const sendSmsNotification = async (toPhoneNumber, message) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/sms/send`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ to: toPhoneNumber, message }),
        });

        if (!response.ok) {
            console.error("Failed to send SMS:", await response.text());
            return { success: false, error: "Failed to send SMS" };
        }

        return { success: true, message: "SMS sent successfully!" };
    } catch (error) {
        console.error("Error sending SMS:", error);
        return { success: false, error: error.message };
    }
};
