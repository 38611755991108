import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Navbar from '../../components/Navbar';

const AdminCalendar = () => {
    const [events, setEvents] = useState([]);
    const [selectedSlots, setSelectedSlots] = useState([]);
    const [notification, setNotification] = useState({ message: '', isError: false });
    const [selectedDays, setSelectedDays] = useState({}); // Track selected days

    const showNotification = (message, isError = false) => {
        setNotification({ message, isError });
        setTimeout(() => setNotification({ message: '', isError: false }), 3000);
    };

    const generateEmptySlots = (start, end) => {
        const slots = [];
        const current = new Date(start);
        while (current < new Date(end)) {
            const next = new Date(current.getTime() + 60 * 60 * 1000);
            slots.push({
                id: `empty-${current.toISOString()}`,
                title: '',
                start: current.toISOString(),
                end: next.toISOString(),
                backgroundColor: 'lightgray',
                borderColor: 'lightgray',
                extendedProps: { isAvailable: false, isEmpty: true },
            });
            current.setTime(next.getTime());
        }
        return slots;
    };

    const fetchSlots = async (start, end) => {
        try {
            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/TimeSlots/timeslots-range?startDate=${start}&endDate=${end}`
            );
            const data = await response.json();
            let allSlots = generateEmptySlots(start, end);

            if (data.availableSlots) {
                const formattedSlots = data.availableSlots.map((slot) => ({
                    id: slot.timeSlotId.toString(),
                    title: slot.isAvailable ? 'Available' : 'Unavailable',
                    start: new Date(slot.startDate).toISOString(),
                    end: new Date(slot.endDate).toISOString(),
                    backgroundColor: slot.isAvailable ? 'green' : 'red',
                    borderColor: slot.isAvailable ? 'green' : 'red',
                    extendedProps: { isAvailable: slot.isAvailable, isEmpty: false },
                }));

                allSlots = allSlots.map((slot) => {
                    const matchingSlot = formattedSlots.find(
                        (fs) => fs.start === slot.start && fs.end === slot.end
                    );
                    return matchingSlot || slot;
                });
            }
            setEvents(allSlots);
        } catch (error) {
            console.error('Error fetching slots:', error);
            showNotification('Error fetching slots from server.', true);
        }
    };

    const handleDaySelection = (dateStr) => {
        if (!dateStr || isNaN(new Date(dateStr).getTime())) {
            console.error("Invalid date selected:", dateStr);
            showNotification("Invalid date selected.", true);
            return;
        }

        const formattedDate = new Date(dateStr).toDateString();
        const slotsForDay = events.filter(
            (event) => new Date(event.start).toDateString() === formattedDate
        );
        const slotIds = slotsForDay.map((slot) => slot.id);

        setSelectedDays((prev) => {
            const isDaySelected = !!prev[formattedDate];
            const newSelectedSlots = isDaySelected
                ? selectedSlots.filter((id) => !slotIds.includes(id))
                : [...selectedSlots, ...slotIds.filter((id) => !selectedSlots.includes(id))];

            setSelectedSlots(newSelectedSlots);
            return { ...prev, [formattedDate]: !isDaySelected };
        });
    };

    const updateSlotStatus = async (endpoint, status) => {
        const token = localStorage.getItem('jwtToken');
        try {
            await Promise.all(
                selectedSlots.map(async (slotId) => {
                    const slot = events.find((e) => e.id === slotId);
                    if (!slot) {
                        console.error(`Slot with ID ${slotId} not found in events.`);
                        return;
                    }

                    if (slotId.startsWith('empty-')) {
                        const createBody = {
                            startDate: slot.start,
                            endDate: slot.end,
                            isAvailable: status,
                        };
                        const createResponse = await fetch(`${process.env.REACT_APP_API_URL}/TimeSlots/bulk`, {
                            method: 'POST',
                            headers: {
                                'Content-Type': 'application/json',
                                Authorization: `Bearer ${token}`,
                            },
                            body: JSON.stringify(createBody),
                        });

                        if (!createResponse.ok) {
                            console.error(`Failed to create slot with ID ${slotId}.`);
                            showNotification('Failed to create a new slot.', true);
                            return;
                        }

                        const createdSlot = await createResponse.json();
                        slot.id = createdSlot.timeSlotId;
                    }

                    const body = { startDate: slot.start, endDate: slot.end };
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/TimeSlots/${endpoint}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(body),
                    });

                    if (response.ok) {
                        setEvents((prevEvents) =>
                            prevEvents.map((event) =>
                                event.id === slotId
                                    ? {
                                        ...event,
                                        id: slot.id,
                                        title: status ? 'Available' : 'Unavailable',
                                        backgroundColor: status ? 'green' : 'red',
                                        borderColor: status ? 'green' : 'red',
                                        extendedProps: { ...event.extendedProps, isAvailable: status, isEmpty: false },
                                    }
                                    : event
                            )
                        );
                    } else {
                        console.error(`Failed to mark slot with ID ${slotId} as ${status ? 'available' : 'unavailable'}.`);
                        showNotification(`Failed to mark slots as ${status ? 'available' : 'unavailable'}.`, true);
                    }
                })
            );
            setSelectedSlots([]);
            showNotification(`Slots marked as ${status ? 'available' : 'unavailable'} successfully.`);
        } catch (error) {
            console.error(`Error marking slots as ${status ? 'available' : 'unavailable'}:`, error);
            showNotification(`Error occurred while marking slots as ${status ? 'available' : 'unavailable'}.`, true);
        }
    };

    const handleDatesSet = (dateInfo) => {
        const startDate = dateInfo.startStr;
        const endDate = dateInfo.endStr;
        fetchSlots(startDate, endDate);
    };

    const toggleSlotSelection = (slotId) => {
        setSelectedSlots((prev) =>
            prev.includes(slotId) ? prev.filter((id) => id !== slotId) : [...prev, slotId]
        );
    };

    return (
        <div>
            <Navbar />
            <h2>Manage Time Slots</h2>
            {notification.message && (
                <div
                    style={{
                        color: notification.isError ? 'white' : 'black',
                        backgroundColor: notification.isError ? 'red' : 'green',
                        padding: '10px',
                        marginBottom: '10px',
                        textAlign: 'center',
                    }}
                >
                    {notification.message}
                </div>
            )}
            <div style={{ marginBottom: '10px' }}>
                <button onClick={() => updateSlotStatus('mark-available', true)}>Mark as Available</button>
                <button onClick={() => updateSlotStatus('mark-unavailable', false)}>Mark as Unavailable</button>
            </div>
            <FullCalendar
                plugins={[timeGridPlugin, interactionPlugin]}
                initialView="timeGridWeek"
                editable={true}
                selectable={true}
                events={events}
                headerToolbar={{
                    left: 'prev,next today',
                    center: 'title',
                    right: 'timeGridWeek',
                }}
                eventContent={(eventInfo) => (
                    <div>
                        <input
                            type="checkbox"
                            checked={selectedSlots.includes(eventInfo.event.id)}
                            onChange={() => toggleSlotSelection(eventInfo.event.id)}
                        />
                        <span>{eventInfo.event.title || 'Empty Slot'}</span>
                    </div>
                )}
                dayHeaderContent={(dayInfo) => {
                    // Ensure dayInfo.date is valid
                    if (!dayInfo.date || isNaN(new Date(dayInfo.date).getTime())) {
                        console.error("Invalid date received:", dayInfo.date);
                        return <div><span>Invalid Date</span></div>;
                    }

                    const formattedDate = new Date(dayInfo.date).toLocaleDateString("en-US", {
                        weekday: "short", // e.g., "Mon"
                        month: "short", // e.g., "Mar"
                        day: "numeric", // e.g., "3"
                    });

                    const isDaySelected = !!selectedDays[formattedDate];

                    return (
                        <div>
                            <input
                                type="checkbox"
                                checked={isDaySelected}
                                onChange={() => handleDaySelection(dayInfo.date)}
                            />
                            <span>{formattedDate}</span>
                        </div>
                    );
                }}

                datesSet={handleDatesSet}
            />

        </div>
    );
};

export default AdminCalendar;
