import React from 'react';
import './ProgressBar.css';
import { useLocation } from 'react-router-dom';

const ProgressBar = () => {
    const location = useLocation();

    // Definiujemy kroki procesu
    const steps = [
        { path: "/", label: "Step 1" },
        { path: "/jobaddress", label: "Step 2" },
        { path: "/estimates", label: "Step 3" },
        { path: "/timeplanner", label: "Step 4" },
        { path: "/jobdetails", label: "Step 5" },
        { path: "/invoice", label: "Step 6" },
        { path: "/ordersummary", label: "Step 7" },
    ];

    // Znajdujemy obecny krok
    const currentStep = steps.findIndex(step => step.path === location.pathname);

    return (
        <div className="progress-container">
            <div className="progress-bar">
                {steps.map((step, index) => (
                    <div
                        key={index}
                        className={`progress-step ${index <= currentStep ? 'active' : ''} ${index < steps.length - 1 ? 'arrow' : ''
                            }`}
                    >
                        <span>{step.label}</span>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default ProgressBar;
