import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

if (pdfFonts?.pdfMake?.vfs) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;
} else {
    console.error("pdfFonts is not loaded correctly. Ensure 'pdfmake' and 'vfs_fonts' are installed properly.");
}

export const generatePurchaseOrderPdf = async (orderDetails) => {
    if (!orderDetails) {
        console.error("No order details provided for generating the PDF.");
        return;
    }

    const docDefinition = {
        content: [
            { text: "Purchase Order", style: "header" },
            { text: " " },
            {
                columns: [
                    {
                        width: "50%",
                        text: [
                            { text: "Supplier Details\n", style: "subHeader" },
                            "Company Name: QELECTRIC LTD\n",
                            "Address: [Company Address]\n",
                            "Contact Email: [Company Email]\n",
                            "Contact Phone: [Company Phone]\n",
                            "VAT Number: [VAT Number]\n",
                            "Company Registration Number: [CRN]\n",
                        ],
                    },
                    {
                        width: "50%",
                        text: [
                            { text: "Client Details\n", style: "subHeader" },
                            `Name: ${orderDetails.recipient_name || "N/A"}\n`,
                            `Address: ${orderDetails.client_address || "N/A"}\n`,
                            `Email: ${orderDetails.client_email || "N/A"}\n`,
                            `Phone: ${orderDetails.client_phone || "N/A"}\n`,
                        ],
                    },
                ],
            },
            { text: " " },
            {
                text: "Description of Services\n",
                style: "subHeader",
            },
            {
                ul: [
                    `Service Details: ${orderDetails.job_description || "N/A"}`,
                    `Location of Services: ${orderDetails.service_location || "N/A"}`,
                ],
            },
            { text: " " },
            {
                text: "Cost Breakdown\n",
                style: "subHeader",
            },
            {
                table: {
                    widths: ["40%", "20%", "20%", "20%"],
                    body: [
                        [
                            { text: "Description", style: "tableHeader" },
                            { text: "Quantity", style: "tableHeader" },
                            { text: "Unit Cost (£)", style: "tableHeader" },
                            { text: "Total Cost (£)", style: "tableHeader" },
                        ],
                        ["Labour Costs", orderDetails.labour_hours || "N/A", orderDetails.labour_cost_per_hour || "N/A", orderDetails.labour_total || "N/A"],
                        ["Materials & Supplies", orderDetails.material_quantity || "N/A", orderDetails.material_unit_cost || "N/A", orderDetails.material_total || "N/A"],
                        ["Additional Charges", "", "", orderDetails.additional_charges || "N/A"],
                        [
                            { text: "Total (Excl. VAT)", colSpan: 3, alignment: "right" },
                            {},
                            {},
                            orderDetails.total_excl_vat || "N/A",
                        ],
                        [
                            { text: "Grand Total", colSpan: 3, alignment: "right", bold: true },
                            {},
                            {},
                            orderDetails.grand_total || "N/A",
                        ],
                    ],
                },
                layout: "lightHorizontalLines",
            },
        ],
        styles: {
            header: {
                fontSize: 22,
                bold: true,
                alignment: "center",
            },
            subHeader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5],
            },
            tableHeader: {
                bold: true,
                fontSize: 12,
                alignment: "center",
            },
        },
    };

    try {
        const pdfDoc = pdfMake.createPdf(docDefinition);
        pdfDoc.getBlob(async (blob) => {
            const formData = new FormData();
            formData.append("file", blob, `PurchaseOrder_${orderDetails.order_id}.pdf`);
            formData.append("orderId", orderDetails.order_id);

            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/purchaseorder/upload`, {
                method: "POST",
                body: formData,
            });

            const result = await response.json();
            if (response.ok) {
                console.log("PDF uploaded successfully:", result.fileUrl);
                return result.fileUrl;
            } else {
                console.error("Error uploading PDF:", result.message);
            }
        });
    } catch (error) {
        console.error("Error generating PDF:", error);
    }
};
