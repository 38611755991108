import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navbar from './Navbar'; // Import the Navbar component
import EstimateInputForm from './EstimateInputForm'; // Import the updated component
import './SharedStyles.css';
import './WelcomePage.css';

const WelcomePage = () => {
    const navigate = useNavigate();
    const [expandedStep, setExpandedStep] = useState(null);

    const toggleStep = (step) => {
        setExpandedStep(expandedStep === step ? null : step);
    };

    const steps = [
        {
            title: "Tell us what needs to be done to help us find the perfect time slot!",
            brief: "",
            details: "Provide as many details as possible about the job – the more we know, the more accurate your estimate will be.",
        },
        {
            title: "Review your estimate!",
            brief: "",
            details: "Feel free to increase or decrease the number of labour hours—your estimate is not binding and it will be mutually agreed upon during the visit."

        },
        {
            title: "Share the work location",
            brief: "",
            details: "Enter the exact address to ensure a smooth scheduling process. Let us know if there’s free parking or if congestion charges apply.",
        },
        {
            title: "Pick a time that works for you!",
            brief: "",
            details: "Select a convenient time from the available slots.",
        },
        {
            title: "Provide job-specific details!",
            brief: "",
            details: "Share contact information for access to the site, and include any helpful materials like pictures or certifications.",
        },
        {
            title: "Enter your invoice details!",
            brief: "",
            details: "Add the necessary information for us to generate an invoice.",
        },
        {
            title: "Review, save, and send your request!",
            brief: "",
            details: "Look out for a confirmation via email, SMS, or WhatsApp.",
        },
    ];

    return (
        <div className="welcome-container">
            <Navbar backPath="/ordersummary" nextPath="/estimates" />

            <div className="blue-box">
                <div className="logo">
                    <img src="/mainlogo.png" alt="QElectric Logo" />
                </div>

                <div className="instruction">
                    <h2>Book Your Electrical Services in 7 Easy Steps!</h2>
                    <ol className="instruction-list">
                        {steps.map((step, index) => (
                            <li key={index} className="instruction-step">
                                <button
                                    className={`step-button ${expandedStep === index ? 'active' : ''
                                        }`}
                                    onClick={() => toggleStep(index)}
                                >
                                    <div className="circle">{index + 1}</div>
                                    <b>{step.title}</b> {step.brief}
                                </button>
                                {expandedStep === index && (
                                    <div className="step-details">{step.details}</div>
                                )}
                            </li>
                        ))}
                    </ol>
                </div>
            </div>
           

            <div className="content">
                <EstimateInputForm />
            </div>
        </div>
    );
};

export default WelcomePage;
